<template>
  <transition name="slide">
    <div v-if="visible" class="drawer">
      <button @click="closeDrawer" class="close-btn float-right">Close</button>
      <div class="content">
        <p class="name mb-0">{{ person.name }}</p>
        <p>{{ person.title }}</p>
        
        <p class="section-heading mb-3" v-if="person.emails.length > 0"><b>Emails</b></p>
        <template v-for="email in person.emails">
          <p>{{ email.address }}</p>
        </template>
          
        <p class="section-heading mb-3" v-if="person.phones.length > 0"><b>Phone Numbers</b></p>
        <template v-for="phone in person.phones">
          <p>{{ formatPhoneNumber(phone.number) }}</p>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    person: {
      type: Object
    }
  },
  methods: {
    closeDrawer() {
      this.$emit('close');
    },
    formatPhoneNumber(phoneNumber) {
      const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove non-numeric characters

      let formattedNumber = '';
  
      if (cleaned.length === 10) {
        // Format as (XXX) XXX-XXXX
        formattedNumber = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      } else if (cleaned.length === 11 && cleaned[0] === '1') {
        // Format as +1 (XXX) XXX-XXXX for 11-digit numbers starting with country code 1
        formattedNumber = cleaned.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
      } else {
        // If the number doesn't match the expected format, return it as is
        formattedNumber = phoneNumber;
      }

      return formattedNumber;
    }
  }
};
</script>

<style scoped>
.drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 600px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter {
  transform: translateX(100%); /* Fully off-screen */
}

.slide-enter-to {
  transform: translateX(0); /* Fully visible */
}

.slide-leave {
  transform: translateX(0); /* Fully visible */
}

.slide-leave-to {
  transform: translateX(100%); /* Off-screen */
}

.drawer .close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
}

.content {
  padding: 40px 64px 40px 40px;
}

.name {
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: 0.15px;
}

.section-heading {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.25px;
}
</style>