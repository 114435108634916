<template>
  <div>
    <Reonomy />
    
    <div class="mb-2 row text-end">
      <div class="col-sm">
        <button class="btn btn-secondary" v-b-modal="`modal-set-owner`">Set Contact(s)</button>
        <b-modal id="modal-set-owner" size="lg" title="Set Owner" :hide-footer="true" @hidden="fetchDefaultContact">
          <PropertyOwnerList :property="property"/>
        </b-modal>
      </div>
    </div>
    
    <OutreachForm :log="outreach_log" @logChange="newLogChanged" @fetch-logs="fetchLogs" />
    
    <div class="mb-2">
      <button class="btn btn-secondary" :disabled="updating_to_close_io" @click="updateToCloseIO">
        {{ updating_to_close_io ? 'Updating to Close.io...' : 'Upload Lead to Close.io' }}
      </button>
    
      <template v-if="contactHasCrmContactId">
        <button class="btn btn-secondary" @click="enrollContactInSequence">Enroll in Close.io Sequence</button>
      </template>
    </div>

    <CallbackReminderForm :property="property" />
    
    <OutreachLogs :outreach_logs="outreach_logs" :activities="activities" />
  </div>
</template>

<script>
import axios from "axios"

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

import CallbackReminderForm from './CallbackReminderForm.vue'
import OutreachForm from './OutreachForm.vue'
import PropertyOwnerList from './PropertyOwnerList.vue'
import OutreachLogs from './OutreachLogs.vue'
import Reonomy from '../Reonomy.vue'

export default {
  components: {
    OutreachForm,
    PropertyOwnerList,
    CallbackReminderForm,
    OutreachLogs,
    Reonomy
  },
  props: [
    "property"
  ],
  data: function(){
    return {
      outreach_log: {
        person_name: '',
        person_phone: '',
        person_email: '',
        company_name: '',
        notes: '',
        answered: false,
        outreach_type: 'Call',
        contact_date: null,
        callback_reminder_at: null
      },
      outreach_logs: [],
      activities: [],
      updating_to_close_io: false,
      contact: '',
      sequence: ''
    }
  },
  mounted() {
    this.outreach_log.contact_date = this.getDateStringNow()
    this.fetchLogs()
    this.fetchDefaultContact().then(() => {
      if (this.contact.close_io_lead_id) {
        this.getCrmActivites()
      }
    })
    this.getCrmSequences()
  },
  computed: {
    createdAtDate() {
      var date = new Date(this.property.created_at);
      var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      
      return `${date.toLocaleDateString("en-US", options)} ${date.toLocaleTimeString()}`;
    },
    contactDateDefault: {
      get() {
        const now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        now.setMilliseconds(null)
        now.setSeconds(null)
        
        this.outreach_log.contact_date = now.toISOString().slice(0, -1);
        
        return now.toISOString().slice(0, -1);
      },
      set(val) {
        this.outreach_log.contact_date = val;
      },
    },  
    contactHasCrmContactId() {
      return this.contact.close_io_contact_id != null
    },
  },
  methods: {
    disableArrowKey(status) {
      this.$store.commit("setArrowKeyDisabled", status)
    },
    fetchDefaultContact() {
      return new Promise((res, rej) => {
        const propertyId = this.$route.params.id;

        axios.get(`/properties/${propertyId}/contacts/last.json`)
        .then(response => {
          this.contact = response.data
        
          let contact = response.data
        
          this.outreach_log.company_name = contact.company_name
          this.outreach_log.person_name = contact.name
          this.outreach_log.person_email = contact.email
          this.outreach_log.person_phone = contact.phone_number
          
          res(response)
        })
        .catch(error => {
          rej(error)
        })
      })
    },
    getCrmSequences() {
      axios.get(`/crm/sequences.json`)
      .then(response => {
        this.sequence = response.data.filter((s) => s.name == 'Call Borrower')[0]
      })
      .catch(error => {})
    },
    enrollContactInSequence() {
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      
      axios.post('crm/sequences/subscribe_contact', {
        contact_id: this.contact.close_io_contact_id,
        sequence_id: this.sequence.id })
      .then(response => {
        console.log(response)
        notyf.success('Contact Enrolled in Call Borrower Sequence!')
      }).catch(error => {})
    },
    updateOwnerDone(contact) {
      this.$bvModal.hide('modal-set-owner')
      this.outreach_log.person_name = contact.name
      this.outreach_log.person_email = contact.email
      this.outreach_log.person_phone = contact.phone_number
    },
    getDateStringNow() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      now.setMilliseconds(null)
      now.setSeconds(null)
      return now.toISOString().slice(0, -1);
    },
    newLogChanged(log) {
      this.outreach_log = log
    },
    updateToCloseIO() {
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      
      this.updating_to_close_io = true;
      
      const propertyId = this.$route.params.id;
      
      axios.post(`/properties/${propertyId}/outreach_logs/update_to_close_io.json`, { outreach_log: this.outreach_log })
      .then((response) => {
        notyf.success('Close.io updated!')
        this.fetchDefaultContact()
      })
      .catch((error) => {
        notyf.error('Oops, notes could not be updated')
      })
      .finally(() => {
        this.updating_to_close_io = false
      })
    },
    fetchLogs() {
      const propertyId = this.$route.params.id;
      
      axios.get(`/properties/${propertyId}/outreach_logs.json`)
      .then(response => {
        this.outreach_logs = response.data
      })
      .catch(error => {
        console.log(error);
      })
    },
    getCrmActivites() {
      axios.get(`/crm/activities.json`, {
        params: { lead_id: this.contact.close_io_lead_id }
      })
      .then(response => {
        this.activities = response.data.data
      })
      .catch(error => {
        console.log(error)
      })
    }
  }
}
</script>