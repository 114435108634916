<template>
  <div>
    <button @click="fetchReonomyContacts" :disabled="fetching">Find Contacts</button>
    <span v-if="fetching">Finding Contacts...</span>
        
    <table class="table" v-if="flattenedPersons.length > 0">
      <thead>
        <tr>
          <th style="width: 25%">Name</th>
          <th style="width: 30%">Company</th>
          <th style="width: 20%">Title</th>
          <th style="width: 20%; text-align:right">Contact Info</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="person in flattenedPersons" style="margin-bottom: 10px">
          <td class="fw-bold">{{ person.name }}</td>
          <td>{{ person.company }}</td>
          <td>{{ person.title }}</td>
          <td style="text-align:right"><a href="#" @click.prevent="openDrawer(person)">Show</a></td>
        </tr>
      </tbody>
    </table>
    
    <ContactDrawer :visible="drawerVisible" @close="closeDrawer" :person="selectedPerson" />
  </div>
</template>

<script>
import axios from "axios"
import ContactDrawer from './ContactDrawer.vue'

export default {
  components: {
    ContactDrawer
  },
  data: function() {
    return {
      contacts: [],
      drawerVisible: false,
      selectedPerson: null,
      flattenedPersons: [],
      fetching: false
    }
  },
  methods: {
    openDrawer(contact) {
      this.selectedPerson = contact;
      this.drawerVisible = true;
    },
    closeDrawer() {
      this.selectedPerson = null;
      this.drawerVisible = false;
    },
    flattenContacts(contacts) {
      contacts.forEach(contact => {
        contact.persons.forEach(person => {
          this.flattenedPersons.push({
            contactType: contact.contact_type,
            name: person.display,
            title: person.jobs.length > 0 ? person.jobs[0].title : 'Individual',
            company: contact.contact_type === 'company' ? contact.company.name : "",
            emails: person.emails,
            phones: person.phones
          });
        });
      });
    },
    fetchReonomyContacts() {
      console.log('fetchReonomyContacts');
      this.fetching = true
      axios.get(`/reonomy/${this.$route.params.id}`)
        .then(response => {
          console.log(response)
          console.log(response.data.contacts)
          this.fetching = false
          this.flattenContacts(response.data.contacts)
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

